import { useNavigate } from "react-router-dom";
import { Layout, Menu, Tag, Button, Drawer } from "antd";
import Logo from "../../assets/yak-banner-dark.svg";
import { useTranslation } from "react-i18next";
import { FaBars, FaArrowRight } from "react-icons/fa";
import { useState } from "react";

const Navbar = () => {
  const { Header } = Layout;

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const headerItems = [
    {
      key: "",
      label: t("home"),
      navigate: "",
    },
    {
      key: "about",
      label: t("about"),
      navigate: "about",
    },
    {
      key: "contact",
      label: t("contact"),
      navigate: "contact",
    },
    {
      key: "switchLang",
      label:
        i18n.language === "tr" ? (
          <Tag color="#334a97">EN</Tag>
        ) : (
          <Tag color="#6b727c">TR</Tag>
        ),
    },
  ];

  const handleMenuClick = async (e) => {
    if (e.key === "switchLang") {
      await i18n.changeLanguage(i18n.language === "tr" ? "en" : "tr");
    } else {
      navigate(e.key);
    }
  };

  return (
    <Header
      style={{
        zIndex: 1,
        width: "100%",
        backgroundColor: "#fff",
      }}
      className="grid items-stretch px-4 sticky top-0 shadow-md"
    >
      <div className="flex items-center self-center justify-between md:container md:mx-auto">
        <a href="/" className="">
          <img src={Logo} alt="" width={150} className="min-w-[150px]" />
        </a>
        <Button
          className="md:hidden"
          size="large"
          type="text"
          style={{
            fontSize: "1.5rem",
            color: "#334a97",
          }}
          onClick={showDrawer}
        >
          <FaBars />
        </Button>
        <Drawer
          placement="right"
          onClose={onClose}
          open={open}
          size="small"
          closeIcon={null}
          className="flex min-h-screen"
          bodyStyle={{ padding: 0 }}
          width={300}
          
        >
          <a href="/" className="justify-self-center">
            <img src={Logo} alt="" className="w-1/2 mx-auto my-4" />
          </a>
          <Menu
            className="justify-end w-full py-2 px-2"
            theme="light"
            mode="vertical"
            items={headerItems}
            selectedKeys={headerItems
              .filter(
                (item) =>
                  item.navigate === window.location.pathname.split("/")[1]
              )
              .map((item) => item.key)}
            onClick={(e) => {
              handleMenuClick(e);
              setOpen(false);
            }}
          />
          <Button
            onClick={onClose}
            type="text"
            size="large"
          className="bottom-0 w-full absolute mb-4"
          >
            <FaArrowRight 
            className="mx-auto"
            />
          </Button>
        </Drawer>
        <Menu
          className="justify-end w-full hidden md:flex"
          theme="light"
          mode="horizontal"
          items={headerItems}
          selectedKeys={headerItems
            .filter(
              (item) => item.navigate === window.location.pathname.split("/")[1]
            )
            .map((item) => item.key)}
          onClick={(e) => handleMenuClick(e)}
        />
      </div>
    </Header>
  );
};

export default Navbar;
