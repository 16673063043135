import React from "react";
import Logo from "../../assets/yak-banner-light.svg";
import { Row, Col, Typography, Image } from "antd";
import { FaPhoneAlt } from "react-icons/fa";

const FooterComponent = () => {
  return (
    <div className="flex justify-center">
      <Col>
        <Row className="justify-center">
          <Image src={Logo} preview={false} width={200} />
        </Row>
        <Row className="mt-4 items-center text-center">
          <a
            href="https://maps.app.goo.gl/aPh95wcj9UML89vZ7"
            target="_blank"
            rel="noreferrer"
          >
            <Typography.Text
              style={{
                color: "#c6c6c6",
              }}
            >
              Cevizlik Mah. Üstoğlu Ap. No: 24/2 D: 5
              34142 Bakırköy/İstanbul
            </Typography.Text>
          </a>
        </Row>
        <Row className="justify-center mt-2">
          <a href="tel:+902125611820">
            <Typography.Text
              style={{
                color: "#c6c6c6",
              }}
              className="flex items-center text-center"
            >
              <FaPhoneAlt className="mr-2" />
              (0212) 561 18 20
            </Typography.Text>
          </a>
        </Row>
        <Row className="justify-center mt-4">
          <Typography.Text
            style={{
              color: "#c6c6c6",
            }}
            className="flex items-center"
          >
            Copyright © 2023 Y.A.K. IP & Law Office
          </Typography.Text>
        </Row>
      </Col>
    </div>
  );
};

export default FooterComponent;
