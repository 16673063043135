import { Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
  FaTrademark,
  FaFilePowerpoint,
  FaPenNib,
  FaFileContract,
  FaLightbulb,
} from "react-icons/fa6";
import { FaGlobeAmericas, FaSearch } from "react-icons/fa";
import CardWithContent from "../../components/content-card";

const Study = () => {
  const { Title, Text } = Typography;
  const { t } = useTranslation();

  const studyFields = [
    {
      icon: <FaFilePowerpoint size={20} />,
      title: t("studyFields.patentOrUtilityModelTitle"),
      content: t("studyFields.patentOrUtilityModelContent"),
    },
    {
      icon: <FaPenNib size={20} />,
      title: t("studyFields.industrialDesignsTitle"),
      content: t("studyFields.industrialDesignsContent"),
    },
    {
      icon: <FaGlobeAmericas size={20} />,
      title: t("studyFields.geographicalIndicationsTitle"),
      content: t("studyFields.geographicalIndicationsContent"),
    },
    {
      icon: <FaTrademark size={20} />,
      title: t("studyFields.brandTitle"),
      content: t("studyFields.brandContent"),
    },
    {
      icon: <FaFileContract size={20} />,
      title: t("studyFields.patentAndBrandResearchTitle"),
      content: t("studyFields.patentAndBrandResearchContent"),
    },
    {
      icon: <FaSearch size={20} />,
      title: t("studyFields.mockItemTitle"),
      content: t("studyFields.mockItemContent"),
    },
    {
      icon: <FaLightbulb size={20} />,
      title: t("studyFields.intellectualPropertyTitle"),
      content: t("studyFields.intellectualPropertyContent"),
    },
  ];

  return (
    <div className="container mx-auto">
      <div className="mx-4">
        <Row>
          <Title level={4}>{t("studyFields.sectionTitle")}</Title>
        </Row>
        <Row className="mb-4">
          <Text>{t("studyFields.sectionDescription")}</Text>
        </Row>
        <Row>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 flex items-center">
            {studyFields.slice(0, 4).map((item, index) => (
              <div key={index} className="col-span-1 h-full">
                <CardWithContent data={item} />
              </div>
            ))}
          </div>
        </Row>
        <Row className="mt-4">
          <div className="hidden lg:grid lg:grid-cols-3 gap-4 flex items-center">
            {studyFields.slice(4, 7).map((item, index) => (
              <div key={index} className="col-span-1 h-full">
                <CardWithContent data={item} />
              </div>
            ))}
          </div>
          <div className="lg:hidden grid grid-cols-1 sm:grid-cols-2 flex items-center gap-4">
            {studyFields.slice(4, 6).map((item, index) => (
              <div key={index} className="col-span-1 h-full">
                <CardWithContent data={item} />
              </div>
            ))}
          </div>
        </Row>
        <Row className="mt-4">
          <div className="lg:hidden grid grid-cols-1 flex items-center gap-4">
            {studyFields.slice(6, 7).map((item, index) => (
              <div key={index} className="col-span-1 h-full">
                <CardWithContent data={item} />
              </div>
            ))}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default Study;
