import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  tr: {
    translation: {
      home: "Ana Sayfa",
      about: "Hakkımızda",
      contact: "İletişim",
      contactUs: "Bize Ulaşın",
      homepage: {
        heroContent:
          "27 yıllık tecrübeyle, vekili olduğumuz yerli ve yabancı pek çok şirketin fikri ve sinai mülkiyet haklarının tescili ve sair koruma hizmetleri yanında, müvekkillerimizin bu alana ilişkin hukuki ihtilaflarının çözümünde de danışmanlık ve avukatlık hizmeti veriyoruz.",
        about1:
          "YAK IP & Law, Patent ve Marka Vekili Avukat Yusuf Avni Kabacaoğlu tarafından 1996 yılında kurulan bir hukuk bürosudur. YAK IP & Law, 27 yıldır birçok yerli ve yabancı şirketin fikri ve sınai mülkiyet haklarının kaydı ve korunması yanı sıra, müvekkillerinin bu alandaki hukuki ihtilaflarının çözümünde danışmanlık ve avukatlık hizmeti sunmaktadır.",
        about2:
          "2018'den itibaren, YAK IP & Law adı altında yeni katılımlarla genişletilen avukat kadrosuyla daha etkin hizmet sunmaya devam etmektedir. Daha önce YAK Law Office'e teknik danışmanlık hizmeti veren Boğaziçi Üniversitesi öğretim üyesi Prof. Dr. Yani Skarlatos, 2018'den itibaren YAK IP'de Patent Departmanı'ndan sorumlu Ortak olarak faaliyet göstermektedir. Akademi ve endüstri arasındaki güçlü ilişki avantajından yararlanan deneyimli avukat kadrosuyla, YAK IP & Law; Patent, Marka, Endüstriyel Tasarım,Coğrafi İşaret ve diğer fikri ve sınai hakların korunması konusunda en doğru seçeneklerden biridir.",
      },
      studyFields: {
        sectionTitle: "Çalışma Alanlarımız",
        sectionDescription:
          "Y.A.K. IP & Law Office olarak sizlere çeşitli alanlarda hizmetler sunuyoruz.",
        patentOrUtilityModelTitle: "Patent / Faydalı Model",
        patentOrUtilityModelContent:
          "Patent veya faydalı model, bir buluşun kullanımı için sahibine belirli bir süre boyunca tanınan tekel hakkı ve bu hakkı gösteren belgenin adıdır. Buluşlar, faydalı model veya patent ile korunur.",
        industrialDesignsTitle: "Endüstriyel Tasarımlar",
        industrialDesignsContent:
          "Bir ürünün estetik yönünü oluşturan gizlilik, şekil, renk, biçim ve dokular gibi insan duyuları tarafından algılanan çeşitli özelliklerin görünümünü temsil eder.",
        geographicalIndicationsTitle: "Coğrafi İşaretler",
        geographicalIndicationsContent:
          "Tüketicilere bir ürünün kökenini, özgün niteliklerini ve bu özgün nitelikler ile coğrafi bölge arasındaki bağlantıyı gösteren bir kalite işaretidir.",
        brandTitle: "Marka",
        brandContent:
          "Bir işletmenin ürünlerini veya hizmetlerini diğer işletmelerden ayırt etmek amacıyla kullanılan çeşitli işaretler aracılığıyla, sözcüklerden şekillere, renklerden harflere, seslerden kokulara kadar farklı öğeleri içerebilen bir tanıtıcıdır.",
        patentAndBrandResearchTitle: "Patent ve Marka Araştırması",
        patentAndBrandResearchContent:
          "Patent veya faydalı model için dünya genelinde daha önce yapılmış başvuruları tespit etmek amacıyla sunulur.",
        mockItemTitle: "Taklit Ürün Takibi",
        mockItemContent:
          "'Birebir benzer' veya 'orijinalin kopyası' olarak yapılan bir satış stratejisidir. Özellikle internet üzerinden satış yapan birçok firma, ana marka modelini temel alarak bu yöntemi kullanmaktadır.",
        intellectualPropertyTitle: "Fikri Haklar",
        intellectualPropertyContent:
          "Fikri Haklar, zihinsel yaratıları koruyan genel bir terimdir ve insan zekası tarafından üretilen edebiyat ve sanat eserleri, bilgisayar programları, tasarımlar gibi çeşitli ürünleri içerir.",
      },
      aboutPage: {
        title: "Hakkımızda daha fazla bilgi edinin",
        about1: "Y.A.K. IP & Law Office, Patent ve Marka Vekili Avukat Yusuf Avni Kabacaoğlu tarafından 1996 yılında 'Y.A.K. Law Office' adı altında kurulmuştur. Y.A.K. Law Office, 27 yıldır yerli ve yabancı birçok şirketin Fikri ve Sınai Mülkiyet Hakları'nın tescili ve diğer koruma hizmetleri yanı sıra, müvekkillerinin bu alanda yaşadığı hukuki ihtilaflarına danışmanlık ve avukatlık hizmeti vermektedir.",
        about2: "2018 yılından itibaren Y.A.K. IP & Law Office adı altında yeni katılımlarla güçlenen avukat kadrosu ile daha etkin hizmet sunmaya devam etmektedir. Daha önce Y.A.K. Law Office'e teknik danışmanlık hizmeti veren Boğaziçi Üniversitesi öğretim üyesi Prof. Dr. Yani Skarlatos, 2010 yılından itibaren Y.A.K. IP'de Patent Departmanından sorumlu Partner olarak faaliyet göstermektedir.",
        about3: "Akademi ve endüstri ile doğrudan temas avantajının yanı sıra, deneyimli avukat kadrosuyla Y.A.K. IP & Law Office; Patent, Marka, Endüstriyel Tasarım, Coğrafi İşaret ve diğer fikri ve sınai haklar korumasında en doğru seçeneklerden birini sunmaktadır.",
        foundingAttorney: "Kurucu Avukat",
        patentBrandAttorney: "Patent ve Marka Vekili",
        attorney: "Avukat",
        technicalAdvisor: "Teknik Danışman",
        assistant: "Asistan",
        yak: "Y.A.K. IP & Property kurucusu Patent ve Marka Vekili Av. Yusuf Avni Kabacaoğlu, 1989 yılında İstanbul Üniversitesi Hukuk Fakültesi'nden mezun oldu. İstanbul Barosu'na kayıtlı serbest avukatlık yaparken, 1996 yılından itibaren kesintisiz bir biçimde Türk Patent ve Marka Kurumu'nda Patent ve Marka Vekilliği faaliyetini sürdürmektedir.",
        kk: "1979 yılında İstanbul Üniversitesi Hukuk Fakültesi'nden mezun olan Kaya Kabacaoğlu, 1982-2006 yılları arasında Türkiye'nin çeşitli bölgelerinde Cumhuriyet Savcısı olarak görev yapmıştır. 2007 yılı itibariyle ise İstanbul Barosu'na kayıtlı serbest avukat olarak çalışmaktadır.",
        ys1: "Y.A.K. IP & Property'nin Akademik Danışmanı ve Patent Departmanı Sorumlusu Prof. Dr. Yani Skarlatos, 1970 yılında Robert Kolej Yüksekokulu Elektrik Mühendisliği Bölümü'nden mezun oldu. Yale Üniversitesi, New Haven, Connecticut'da Elektrik Mühendisliği (Fiziksel Elektronik) alanında yüksek lisans ve doktora eğitimi aldı ve aynı bölümde misafir öğretim üyeliği yaptı. Robert Kolej Yüksekokulu'nda 1968-1970 yılları arasında asistan olarak, Boğaziçi Üniversitesi'nde 1974-1983 yılları arasında doçent unvanıyla öğretim görevlisi olarak çalıştı.",
        ys2: "Kısmi statüde İstanbul Üniversitesi'nde profesör unvanıyla görev yaparak, 1992-2014 yılları arasında yarıiletken ve yalıtkanların elektronik ve optoelektronik özellikleri, ince filmler, kaotik davranışlar ve yüzeylerin optik karakterizasyonu alanlarında bilimsel araştırmalar yürüttü. Ayrıca 2014 yılından bu yana Boğaziçi, Yeditepe ve İstanbul Şehir Üniversiteleri'nde ders veriyor ve çeşitli kurumlarda teknik danışmanlık yapıyor.",
        ys3: "Boğaziçi Üniversitesi Yüksek Akım Laboratuvarı'nın akredite edilmiş birkaç laboratuvardan biri olmasının yanı sıra, halen Y.A.K. Law Office'a danışmanlık yapmışken, 2016 yılından itibaren Y.A.K. IP'in Patent Departmanı'nda sorumlu ortak olarak faaliyet göstermektedir.",
        dsk: "2016 yılında Bahçeşehir Üniversitesi Hukuk Fakültesi'nden mezun olan Dilşad Seher Kabacaoğlu, 2017 yılı itibariyle İstanbul Barosu'na kayıtlı avukat olarak çalışmaktadır.",
        bg: "2017 yılında İstanbul Üniversitesi Siyaset Bilimi ve Uluslararası İlişkiler Bölümü'nden mezun olan Bahar Gövdeli, aynı zamanda İstanbul Üniversitesi Adalet Yüksekokulu'nda eğitim almaktadır."
      }
    },
  },
  en: {
    translation: {
      home: "Home",
      about: "About",
      contact: "Contact",
      contactUs: "Contact Us",
      homepage: {
        heroContent:
          "With 27 years of experience, we provide legal counsel and attorney services for our clients in the registration and protection of intellectual and industrial property rights of many domestic and foreign companies we represent, as well as in resolving their legal disputes in this field.",
        about1:
          "YAK IP & Law is a law firm founded by Patent and Trademark Attorney Yusuf Avni Kabacaoğlu in 1996. YAK IP & Law has been providing legal services for 27 years, including the registration and protection of intellectual and industrial property rights for many domestic and foreign companies, as well as offering consultancy and attorney services in resolving their legal disputes in this field.",
        about2:
          "Since 2018, YAK IP & Law has continued to provide more effective services with a newly expanded attorney team under the name YAK IP & Law. Previously, Boğaziçi University faculty member Prof. Dr. Yani Skarlatos, who provided technical consultancy to YAK Law Office, has been actively serving as a Partner responsible for the Patent Department at YAK IP since 2018. With an experienced attorney team that leverages the strong relationship between academia and industry, YAK IP & Law is one of the most suitable choices for the protection of Patents, Trademarks, Industrial Designs, Geographical Indications, and other intellectual and industrial property rights.",
      },
      studyFields: {
        sectionTitle: "Study Fields",
        sectionDescription:
          "As Y.A.K. IP & Law Office, we offer you services in various fields.",
        patentOrUtilityModelTitle: "Patent / Utility Model",
        patentOrUtilityModelContent:
          "A patent or utility model is the name of the document showing the exclusive right granted to the owner of an invention for a certain period of time for the use of an invention. Inventions are protected by utility model or patent.",
        industrialDesignsTitle: "Industrial Designs",
        industrialDesignsContent:
          "It represents the appearance of various features perceived by human senses such as privacy, shape, color, form, and textures that constitute the aesthetic aspect of a product.",
        geographicalIndicationsTitle: "Geographical Indications",
        geographicalIndicationsContent:
          "It is a quality mark that demonstrates the origin, unique characteristics, and the connection between these unique characteristics and a geographic region to consumers for a product.",
        brandTitle: "Brand",
        brandContent:
          "It is an identifier that encompasses various elements, ranging from words to shapes, colors to letters, sounds to scents, used by a business to distinguish its products or services from those of other businesses.",
        patentAndBrandResearchTitle: "Patent and Trademark Research",
        patentAndBrandResearchContent:
          "It is submitted to identify previously filed applications worldwide for patents or utility models.",
        mockItemTitle: "Counterfeit Product Tracking",
        mockItemContent:
          "It is a sales strategy where products are marketed as 'identical' or 'copies of the original.' Many companies, especially those selling online, employ this method by basing their products on the original brand model.",
        intellectualPropertyTitle: "Intellectual Property",
        intellectualPropertyContent:
          "Intellectual property is the general term for rights that protect intellectual creations, encompassing works of literature and art, computer programs, designs, and various other creations produced by human intellect.",
      },
      aboutPage: {
        title: "Learn more about us",
        about1: "Y.A.K. IP & Law Office was founded under the name 'Y.A.K. Law Office' in 1996 by Patent and Trademark Attorney Yusuf Avni Kabacaoğlu. For 27 years, Y.A.K. Law Office has been providing services for the registration and protection of Intellectual and Industrial Property Rights for numerous local and foreign companies, as well as offering consulting and legal services for their legal disputes in this field.",
        about2: "Since 2018, under the name 'Y.A.K. IP & Law Office' with new additions to its attorney team, it continues to provide more effective services. Previously, Professor Dr. Yani Skarlatos, a technical consultant to Y.A.K. Law Office, has been active as a Partner responsible for the Patent Department at Y.A.K. IP since 2010.",
        about3: "In addition to direct engagement with academia and industry, Y.A.K. IP & Law Office, with its experienced attorney team, is one of the best choices for the protection of Patents, Trademarks, Industrial Designs, Geographical Indications, and other intellectual and industrial property rights.",
        foundingAttorney: "Founding Attorney",
        patentBrandAttorney: "Patent and Trademark Attorney",
        attorney: "Attorney",
        technicalAdvisor: "Technical Advisor",
        assistant: "Assistant",
        yak: "Yusuf Avni Kabacaoğlu, founder of Y.A.K. IP & Law, graduated from Istanbul University Faculty of Law in 1989. While working as a freelance attorney registered with the Istanbul Bar Association, he has been carrying out Patent and Trademark Attorneyship activities at the Turkish Patent and Trademark Office without interruption since 1996.",
        kk: "Kaya Kabacaoğlu, who graduated from Istanbul University Faculty of Law in 1979, served as a Public Prosecutor in various regions of Turkey between 1982-2006. Since 2007, he has been working as a freelance attorney registered with the Istanbul Bar Association.",
        ys1: "Yani Skarlatos, Academic Advisor of Y.A.K. IP & Law and Partner responsible for the Patent Department, graduated from Robert College Department of Electrical Engineering in 1970. He received his master's and doctorate degrees in Electrical Engineering (Physical Electronics) at Yale University, New Haven, Connecticut, and was a visiting faculty member in the same department. He worked as an assistant at Robert College Department of Electrical Engineering between 1968-1970 and as an associate professor at Boğaziçi University between 1974-1983.",
        ys2: "He worked part-time as a professor at Istanbul University, conducting scientific research in the fields of semiconductor and insulator electronic and optoelectronic properties, thin films, chaotic behaviors, and optical characterization of surfaces between 1992-2014. He also teaches at Boğaziçi, Yeditepe, and Istanbul Şehir Universities since 2014 and provides technical consultancy to various institutions.",
        ys3: "In addition to being one of the few accredited laboratories of the Boğaziçi University High Current Laboratory, he has been working as a Partner responsible for the Patent Department at Y.A.K. Law Office since 2016.",
        dsk: "Dilşad Seher Kabacaoğlu, who graduated from Bahçeşehir University Faculty of Law in 2016, has been working as an attorney registered with the Istanbul Bar Association since 2017.",
        bg: "Bahar Gövdeli, who graduated from Istanbul University Department of Political Science and International Relations in 2017, is also studying at Istanbul University Justice Vocational School."
      }
    },
  },
};
i18n.use(initReactI18next).init({ lng: "tr", debug: false, resources });
