import { Typography, Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";
import { useNavigate } from "react-router-dom";

const Hero = ({data}) => {
  const { Title, Paragraph } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const Wrapper = (props) => (
    <ParallaxProvider>
      <div className="h-96">
        <div className="w-full flex items-center">{props.children}</div>
      </div>
    </ParallaxProvider>
  );

  return (
    <div>
      <Wrapper>
        <ParallaxBanner
          layers={[
            { image: data.image, speed: -20 },
            {
              speed: -15,
              children: (
                <div className="absolute inset-0 flex items-center justify-center pb-10">
                  <Col>
                    <Row className="justify-center">
                      <Title
                        level={2}
                        className="text-white text-center"
                        style={{
                          color: "#fff",
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                        }}
                      >
                        {data.title}
                      </Title>
                    </Row>
                    <Row className="justify-center text-center">
                      <Paragraph style={{ color: "#fff" }} className="w-1/2">
                        {data.content}
                      </Paragraph>
                    </Row>
                    {data?.buttonType === "ContactUs" && (
                    <Row className="justify-center">
                      <Button
                      style={{
                        color: "#fff",
                        border: "none",
                      }}
                      className="hover:bg-[#213269] bg-[#334a97] mt-4"
                      onClick={() => navigate("/contact")}
                      >
                        {t("contactUs")}
                      </Button>
                    </Row>
                    )}
                  </Col>
                </div>
              ),
            },
          ]}
          className="h-96 bg-cover"
        />
      </Wrapper>
    </div>
    
  );
};

export default Hero;
