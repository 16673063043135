import TeamMemberCard from "../../components/team-card";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

const OurTeam = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto mt-8">
      <div className="mx-4">
        <Row>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 flex items-center">
            <div className="col-span-1 h-full">
              <TeamMemberCard
                data={{
                  name: "Yusuf Avni Kabacaoğlu",
                  title: t("aboutPage.patentBrandAttorney"),
                  founderTitle: t("aboutPage.foundingAttorney"),
                  description: t("aboutPage.yak"),
                }}
              />
            </div>
            <div className="col-span-1 h-full">
              <TeamMemberCard
                data={{
                  name: "Kaya Kabacaoğlu",
                  title: t("aboutPage.attorney"),
                  description: t("aboutPage.kk"),
                }}
              />
            </div>
          </div>
        </Row>
        <Row className="my-4">
          <div className="grid grid-cols-1 gap-4 flex items-center">
            <div className="col-span-1 h-full">
              <TeamMemberCard
                data={{
                  name: "Prof. Dr. Yani Skarlatos",
                  title: t("aboutPage.technicalAdvisor"),
                  description: t("aboutPage.ys1"),
                  description2: t("aboutPage.ys2"),
                  description3: t("aboutPage.ys3"),
                }}
              />
            </div>
          </div>
        </Row>
        <Row>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 flex items-center">
            <div className="col-span-1 h-full">
              <TeamMemberCard
                data={{
                  name: "Dilşad Seher Kabacaoğlu",
                  title: t("aboutPage.attorney"),
                  description: t("aboutPage.dsk"),
                }}
              />
            </div>
            <div className="col-span-1 h-full">
              <TeamMemberCard
                data={{
                  name: "Bahar Gövdeli",
                  title: t("aboutPage.attorney"),
                  description: t("aboutPage.bg"),
                }}
              />
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default OurTeam;
