import { Divider } from "antd";

const DividerComponent = () => {
  return (
    <div className="container mx-auto w-1/2">
        <Divider />
      </div>
  )
}

export default DividerComponent