import Hero from "../../components/hero";
import AboutImage from "../../assets/about.jpg";
import { useTranslation } from "react-i18next";
import AboutUs from "../../sections/about-us";
import OurTeam from "../../sections/our-team";
import DividerComponent from "../../components/divider";

const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <Hero
        data={{
          image: AboutImage,
          title: t("aboutPage.title"),
        }}
      />
      <AboutUs isHomePage={false} />
      <DividerComponent />
      <OurTeam />
      <DividerComponent />
    </>
  );
};

export default About;
