import { Row, Typography, Card, Avatar } from "antd";
import {
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaApple,
  FaGoogle,
  FaYandexInternational,
} from "react-icons/fa";

const ContactCard = () => {
  const { Text } = Typography;
  return (
    <div>
      <Card className="h-full mr-4">
        <Row className="items-center">
          <Avatar
            shape="square"
            style={{
              backgroundColor: "#334a97",
            }}
            className="flex justify-center items-center mr-3"
            >
            <FaMapMarkerAlt size={15} />
          </Avatar>
          <Text strong>Cevizlik Mah. Üstoğlu Ap. No:24/2 D:5 Bakırköy/İstanbul</Text>
        </Row>
        <hr className="my-4" />
        <Row className="flex items-center">
          <Avatar
            shape="square"
            style={{
              backgroundColor: "#334a97",
            }}
            className="flex justify-center items-center mr-3"
          >
            <FaPhoneAlt size={15} />
          </Avatar>
          <a href="tel:+902125611820">
            <Text strong>(0212) 561 18 20</Text>
          </a>
        </Row>
        <hr className="my-4" />
        <Row className="flex items-center">
          <Avatar
            shape="square"
            style={{
              backgroundColor: "#334a97",
            }}
            className="flex justify-center items-center mr-3"
          >
            <FaApple size={15} />
          </Avatar>
          <a
            href="https://maps.apple.com/?address=Istanbul%20Cd.%2024,%2034142%20Bak%C4%B1rk%C3%B6y%20Istanbul,%20T%C3%BCrkiye&ll=40.978438,28.876078&q=Istanbul%20Cd.%2024"
            target="_blank"
            rel="noreferrer"
          >
            <Text strong>Apple Haritalar</Text>
          </a>
        </Row>
        <Row className="flex items-center mt-4">
          <Avatar
            shape="square"
            style={{
              backgroundColor: "#334a97",
            }}
            className="flex justify-center items-center mr-3"
          >
            <FaGoogle size={15} />
          </Avatar>
          <a
            href="https://maps.app.goo.gl/aPh95wcj9UML89vZ7"
            target="_blank"
            rel="noreferrer"
          >
            <Text strong>Google Haritalar</Text>
          </a>
        </Row>
        <Row className="flex items-center mt-4">
          <Avatar
            shape="square"
            style={{
              backgroundColor: "#334a97",
            }}
            className="flex justify-center items-center mr-3"
          >
            <FaYandexInternational size={15} />
          </Avatar>
          <a
            href="https://yandex.com/maps/-/CDeY6QOb"
            target="_blank"
            rel="noreferrer"
          >
            <Text strong>Yandex Haritalar</Text>
          </a>
        </Row>
      </Card>
    </div>
  );
};

export default ContactCard;
