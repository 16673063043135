import React from "react";

export default function Map(){
    return (
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3012.1387951255665!2d28.87350317624127!3d40.978441521261324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s8GGCXVHG%2B9CG!5e0!3m2!1str!2str!4v1699355516583!5m2!1str!2str" style={
            {
                width: "100%",
                height: "60vh",
                border: "0"
            }
        } loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
    );
  }
