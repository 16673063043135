import React from "react";
import { Row } from "antd";
import Map from "../../sections/map";
import ContactCard from "../../components/contact-card";

const Contact = () => {
  return (
    <div className="container mx-auto mt-8">
      <div className="mx-4">
        <Row className="">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto flex">
            <div className="col-span-1">
              <ContactCard />
            </div>
            <div className="col-span-1">
              <Map />
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
