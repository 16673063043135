import { Row, Typography, Image } from "antd";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/yak-vertical.svg";

const AboutUs = ({isHomePage = true}) => {
  const { Title, Paragraph } = Typography;
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-8">
      <Row>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-4 flex items-center">
          <div className="col-span-1 md:col-span-3">
            <Title level={4}>{t("about")}</Title>
            <Paragraph>
              {isHomePage ? t("homepage.about1") : t("aboutPage.about1")}
              <br />
              <br />
              {isHomePage ? t("homepage.about2") : t("aboutPage.about2")}
              {!isHomePage && (
                <>
                  <br />
                  <br />
                  {t("aboutPage.about3")}
                </>
              )}
            </Paragraph>
          </div>
          <div className="hidden md:col-span-1 md:block mx-auto">
            <Image src={Logo} preview={false} height={200} />
          </div>
        </div>
      </Row>
    </div>
  );
};

export default AboutUs;
