import { ConfigProvider, Layout } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about-us";
import Contact from "./pages/contact";
import FooterComponent from "./sections/footer";
import NotFound from "./pages/not-found";
import Navbar from "./sections/header";
import "./i18n";

function App() {
  const { Content, Footer } = Layout;
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#334a97",
          },
          components: {
            Layout: {
              headerBg: "#fff",
            },
            Menu: {
              horizontalLineHeight: 0,
            }
          },
        }}
      >
        <Router>
          <Layout className="min-h-screen">
            <Navbar />
              <Content>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Content>
            <Footer className="bg-gray-800">
              <FooterComponent />
            </Footer>
          </Layout>
        </Router>
      </ConfigProvider>
    </>
  );
}

export default App;
