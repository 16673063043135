import { Row, Typography, Card } from "antd";

const TeamMemberCard = ({ data }) => {
  const { Paragraph, Text } = Typography;
  return (
    <Card className="shadow-sm h-full">
      <Row className="justify-center">
        <Text className="text-lg" strong>
          {data.name}
        </Text>
      </Row>
      {data?.founderTitle && (
        <Row className="justify-center">
          <Text
            style={{
              color: "#334a97",
            }}
            strong
          >
            {data.founderTitle}
          </Text>
        </Row>
      )}
      <Row className="justify-center">
        <Text
          style={{
            color: "#334a97",
          }}
          strong
        >
          {data.title}
        </Text>
      </Row>
      {!data?.founderTitle && <br />}
      <Row>
        <Paragraph className="mt-4">{data.description}</Paragraph>
      </Row>
      {data?.description2 && (
        <Row>
          <Paragraph className="mt-4">{data.description2}</Paragraph>
        </Row>
      )}
      {data?.description3 && (
        <Row>
          <Paragraph className="mt-4">{data.description3}</Paragraph>
        </Row>
      )}
    </Card>
  );
};

export default TeamMemberCard;
