import { Row, Typography, Card, Avatar } from "antd";

const CardWithContent = ({data}) => {
    const { Paragraph, Text } = Typography;
    return (
        <Card className="shadow-sm hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 h-full">
          <Row className="flex items-center">
            <Avatar
              shape="square"
              style={{
                backgroundColor: "#334a97",
              }}
              className="flex justify-center items-center mr-3"
            >
              {data?.icon}
            </Avatar>
            <Text strong>{data.title}</Text>
          </Row>
          <Row>
            <Paragraph className="mt-4">{data.content}</Paragraph>
          </Row>
        </Card>
      );
}

export default CardWithContent