import React from "react";
import AboutUs from "../../sections/about-us";
import Study from "../../sections/study";
import DividerComponent from "../../components/divider";
import HomeImage from "../../assets/home-hero.jpg";
import Hero from "../../components/hero";
import { useTranslation } from "react-i18next";


const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Hero data={{
        image: HomeImage,
        title: "YAK IP & Law Office",
        content: t("homepage.heroContent"),
        buttonType: "ContactUs",
      }} />
      <AboutUs />
      <DividerComponent />
      <Study />
      <DividerComponent />
      
    </>
  );
};

export default Home;
